var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ic-login"},[_c('div',{staticClass:"ic-login__container"},[_c('div',{staticClass:"ic-login__elements"},[_c('div',{staticClass:"ic-login__content"},[_c('h2',{staticClass:"ic-login__title"},[_vm._v(_vm._s(_vm.cms.page.title))])]),_vm._v(" "),_c('form',{staticClass:"wl-form",attrs:{"autocomplete":"on"},on:{"submit":function($event){return _vm.handleLogin($event)}}},[_c('div',{staticClass:"wl-form__container"},[_c('div',{staticClass:"wl-form__elements"},[_c('h1',{staticClass:"wl-form__title"},[_vm._v(_vm._s(_vm.cms.page.login_title))]),_vm._v(" "),_c('div',{staticClass:"wl-form__wrapper",class:{ 'wl-form__wrapper--error': _vm.hasInvalidInput }},[_vm._l((_vm.renderIf({
                  condition: _vm.formMessage
                })),function(item){return _c('TzMarkdown',{key:`error_${item}`,staticClass:"form-message form-message--error",attrs:{"content":_vm.formMessage}})}),_vm._v(" "),_c('div',{staticClass:"wl-form__form-group"},[_c('label',{staticClass:"wl-form__label"},[_vm._v(_vm._s(_vm.cms.page.email_label))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"wl-form__input",class:{ 'wl-form__input--invalid': _vm.invalidInput.email },attrs:{"type":"email","name":"email","placeholder":_vm.cms.page.email_label},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"wl-form__form-group"},[_c('label',{staticClass:"wl-form__label"},[_vm._v(_vm._s(_vm.cms.page.password_label))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"wl-form__input",class:{
                    'wl-form__input--invalid': _vm.invalidInput.password
                  },attrs:{"type":"password","name":"password","placeholder":_vm.cms.page.password_label},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_vm._v(" "),_c('label',{staticClass:"wl-form__form-group wl-form__form-group--checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rememberMe),expression:"rememberMe"}],staticClass:"wl-form__input",attrs:{"type":"checkbox","name":"remember"},domProps:{"checked":Array.isArray(_vm.rememberMe)?_vm._i(_vm.rememberMe,null)>-1:(_vm.rememberMe)},on:{"change":function($event){var $$a=_vm.rememberMe,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.rememberMe=$$a.concat([$$v]))}else{$$i>-1&&(_vm.rememberMe=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.rememberMe=$$c}}}}),_vm._v(" "),_c('span',{staticClass:"wl-form__label-text"},[_vm._v(_vm._s(_vm.cms.page.remember_label))])]),_vm._v(" "),_c('a',{staticClass:"wl-form__link",attrs:{"href":_vm.cms.page.forgot_link}},[_vm._v(_vm._s(_vm.cms.page.forgot_text))]),_vm._v(" "),_vm._l((_vm.renderIfBoolean({
                  condition: _vm.hasInvalidInput
                })),function(item){return _c('TzMarkdown',{key:`error_${item}`,staticClass:"form-message form-message--error",attrs:{"content":_vm.errorText}})}),_vm._v(" "),_c('button',{staticClass:"wl-form__button button--yellow",class:{ 'wl-form__button--disabled': _vm.isLoggingIn },attrs:{"type":"submit","disabled":_vm.isLoggingIn}},[_c('span',{staticClass:"wl-form__button-text"},[_vm._v(_vm._s(_vm.cms.page.button_text))])])],2)])])]),_vm._v(" "),_c('div',{staticClass:"ic-login__post-text"},[_vm._v("\n        New Here?\n        "),_c('button',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.handleOpenModifySearchModal),expression:"handleOpenModifySearchModal",arg:"tap"}],domProps:{"textContent":_vm._s(`Let's Get Started`)}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }