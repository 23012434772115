<template>
  <div class="ic-login">
    <div class="ic-login__container">
      <div class="ic-login__elements">
        <div class="ic-login__content">
          <h2 class="ic-login__title">{{ cms.page.title }}</h2>
        </div>
        <form
          class="wl-form"
          v-on:submit="handleLogin($event)"
          autocomplete="on"
        >
          <div class="wl-form__container">
            <div class="wl-form__elements">
              <h1 class="wl-form__title">{{ cms.page.login_title }}</h1>
              <div
                class="wl-form__wrapper"
                :class="{ 'wl-form__wrapper--error': hasInvalidInput }"
              >
                <TzMarkdown
                  v-for="item in renderIf({
                    condition: formMessage
                  })"
                  :key="`error_${item}`"
                  class="form-message form-message--error"
                  :content="formMessage"
                />
                <div class="wl-form__form-group">
                  <label class="wl-form__label">{{
                    cms.page.email_label
                  }}</label>
                  <input
                    class="wl-form__input"
                    :class="{ 'wl-form__input--invalid': invalidInput.email }"
                    type="email"
                    name="email"
                    v-model="email"
                    :placeholder="cms.page.email_label"
                  />
                </div>

                <div class="wl-form__form-group">
                  <label class="wl-form__label">{{
                    cms.page.password_label
                  }}</label>
                  <input
                    class="wl-form__input"
                    :class="{
                      'wl-form__input--invalid': invalidInput.password
                    }"
                    type="password"
                    name="password"
                    v-model="password"
                    :placeholder="cms.page.password_label"
                  />
                </div>

                <label
                  class="wl-form__form-group wl-form__form-group--checkbox"
                >
                  <input
                    type="checkbox"
                    name="remember"
                    class="wl-form__input"
                    v-model="rememberMe"
                  />
                  <span class="wl-form__label-text">{{
                    cms.page.remember_label
                  }}</span>
                </label>
                <a class="wl-form__link" :href="cms.page.forgot_link">{{
                  cms.page.forgot_text
                }}</a>

                <TzMarkdown
                  v-for="item in renderIfBoolean({
                    condition: hasInvalidInput
                  })"
                  :key="`error_${item}`"
                  class="form-message form-message--error"
                  :content="errorText"
                />

                <button
                  class="wl-form__button button--yellow"
                  :class="{ 'wl-form__button--disabled': isLoggingIn }"
                  type="submit"
                  :disabled="isLoggingIn"
                >
                  <span class="wl-form__button-text">{{
                    cms.page.button_text
                  }}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="ic-login__post-text">
          New Here?
          <button
            v-text="`Let's Get Started`"
            v-touch:tap="handleOpenModifySearchModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Meta from '@/assets/js/shared/misc/meta.mjs';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import renderIf from '@/assets/js/shared/helpers/renderIf';

const pageCms = require(`@/assets/cms/pages/login.json`);

export default {
  name: 'login',
  components: { TzMarkdown },
  computed: {
    hasInvalidInput() {
      return this.invalidInput.email || this.invalidInput.password;
    }
  },
  data() {
    return {
      defaultErrorText:
        'Unable to login. Please verify your email address and password above.',
      email: null,
      errorText: '',
      isLoggingIn: false,
      invalidInput: {
        email: false,
        password: false
      },
      password: null,
      rememberMe: false,
      formMessage: null,
      renderIf
    };
  },
  methods: {
    handleError(error) {
      this.isLoggingIn = false;
      this.errorText = error ? error : this.defaultErrorText;
    },
    handleSuccessfulLogin() {
      this.invalidInput.email = false;
      this.invalidInput.Password = false;
      this.isLoggingIn = false;
      if (this.rememberMe) {
        localStorage.setItem('rememberMe', this.email);
      }
      const { href } = this.$router.resolve({
        name: 'dashboard-home'
      });
      location.href = `${href}/`;
    },
    async handleLogin($event) {
      $event.preventDefault();
      this.isLoggingIn = true;
      this.invalidInput.email = false;
      this.invalidInput.password = false;
      this.validateEmailAndPassword();

      if (this.hasInvalidInput) {
        console.log('Invalid');
        return;
      }

      /** Company/Testing emails will have company domain, all customers are information domain **/
      const domain =
        this.$config.environment === 'production'
          ? `${this.$config.website}.com`
          : this.$config.testingAccountDomain;

      /** Attempt to login **/
      try {
        const login = await this.$travellingSDK.AuthDomain.login(
          {
            email: this.email,
            password: this.password,
            domain: 'default'
          },
          domain
        );

        /** If call is successful, check for errors **/
        if (!login.msg || login.type) {
          let error = null;
          switch (login.type) {
            case 'locked':
              this.invalidInput.email = true;
              error = `This account has been locked.<br/>Please <a href="${this.cms.page.forgot_link}">reset your password</a> to login.`;
              break;
            case 'password-error':
              /** Invalid Password will highlight password input and change default error message **/
              this.invalidInput.password = true;
              error =
                'Password does not meet requirements. Please verify and resubmit.';
              break;
            default:
              /** All other errors will highlight both inputs **/
              this.invalidInput.email = true;
              this.invalidInput.password = true;
          }
          /** Display error to user **/
          this.handleError(error);
          return;
        }
      } catch (err) {
        /** On failure to hit API, display message with contact info **/
        this.invalidInput.email = true;
        this.invalidInput.password = true;
        this.$bugsnag?.notify(err);
        this.handleError();
        return;
      }

      this.handleSuccessfulLogin();
    },
    renderIfBoolean(opts) {
      if (!opts.condition) {
        return [];
      }

      return [`${opts.condition}`];
    },
    validateEmailAndPassword() {
      if (!this.email && !this.password) {
        this.invalidInput.email = true;
        this.invalidInput.password = true;
        this.handleError('Please enter your email address and password above.');
        return;
      }

      if (
        !this.email ||
        !getRegex({ regexType: 'emailRegex' }).test(this.email)
      ) {
        this.invalidInput.email = true;
        this.handleError('Please enter a valid email address');
        return;
      }

      if (!this.password) {
        this.invalidInput.password = true;
        this.handleError('Please enter a valid password');
      }
    },
    handleOpenModifySearchModal() {
      document.body.classList.add('body--show-modal');
    }
  },
  mounted() {
    // check for registered query param and if it's in session storage
    if (this.$route.query.registered) {
      let registeredEmail;
      try {
        registeredEmail = JSON.parse(sessionStorage.getItem('checkout')).email;
      } catch (err) {
        // Do nothing
        this.$bugsnag?.notify(err);
      }

      this.email = registeredEmail ?? '';
    }

    // If you have an email then write message to login page
    if (this.email) {
      this.formMessage =
        'Looks like you already have an account. Try logging in.';
      return {};
    }

    // Proceed with remember me logic if none of the above
    if (!localStorage.rememberMe) {
      return {};
    }
    const userEmail = localStorage.getItem('rememberMe');
    this.email = userEmail;
    if (userEmail) {
      this.rememberMe = true;
    }
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: pageCms,
          global: require(`@/assets/cms/global.json`)
        };
      }
    }
  },
  head() {
    return {
      title: this.cms.page.page_title,
      meta: this.cms.page.meta ? Meta.convertToNuxtMeta(this.cms.page.meta) : []
    };
  }
};
</script>
